export const productCategory = [
    { id : 1, label : "Vegetables", value : "vegetables"},
    { id : 2, label : "Fruits", value : "fruits"},
    { id : 3, label : "Meat", value : "meat"},
    { id : 4, label : "Electronics", value : "electronics"},
    { id : 5, label : "Grocery", value : "grocery"},
    { id : 6, label : "Local Items", value : "localitems"},
    { id : 7, label : "Cosmetics", value : "cosmetics"},
    { id : 8, label : "Fragrances", value : "fragrances"},
    { id : 9, label : "Food", value : "food"},

]


export const unitCategory = [
    { id : 1, label : "Kg", value : "kg"},
    { id : 2, label : "Gram", value : "gram"},
    { id : 3, label : "Litres", value : "litres"},
    { id : 4, label : "Item", value : "item"},
    // { id : 5, label : "Grocery", value : "grocery"},
    // { id : 6, label : "Local Items", value : "localitems"},


]


