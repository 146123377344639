const BASE_URL = "https://kalingbackstorelatest.onrender.com";
// const BASE_URL = "http://localhost:8000";
const kalingbackstorelatestURL = "https://kalingbackstorelatest.onrender.com";
const yingkiongstoreURLPrevious = "https://yingkiongstore.onrender.com";
const production_URL = "http://localhost:8000";
const AUTH_URLS = {
  SIGN_UP: "auth/signup",
  LOGIN_URL: "auth/login",
};

module.exports = {
  BASE_URL,
  AUTH_URLS,
};
