

import { Link,useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import { useState } from 'react';
// import { resetPasswordAsync, selectError, selectPasswordReset } from '../authSlice';
import { resetPassword } from '../../../API/authAPI';
import EmailFailedResetPasswrod from './EmailFailedveri';
import { toast } from 'react-toastify';


export default function ResetPassword() {
  const [error,setError] = useState('');
//   const passwordReset = useSelector(selectPasswordReset);
//   const error = useSelector(selectError)

const navigate = useNavigate();
  const query = new URLSearchParams(window.location.search);
  const token = query.get('token')
  const email = query.get('email')
  const [btnLoadin,setBtnLoading]=useState(false)

  const dispatch = useDispatch()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // console.log(errors);
  // console.log(email, token)
  const notifyAdd = () => toast.success("Password Reset Successfully!");
  const onSubmit = async (data) => {
    setBtnLoading(true)
    try {
      const requestData = {
        email: email,
        password: data.password,
        token: token
      };

      const response = await resetPassword(requestData);
      
      if (response) {
        console.log(response); // Success message in the console
        notifyAdd()
      
        navigate('/login');  // Redirect to login page
      } else {
        setError("Failed to reset password. Please try again.");
      }
    } catch (error) {
      setError("An error occurred while resetting the password.");
    }
  };

  return (
    <>
      {email && token ? (
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            {/* <img
          className="mx-auto h-10 w-auto"
          src="/ecommerce.png"
          alt="Your Company"
        /> */}
            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
              Enter New Password
            </h2>
          </div>

          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form
              noValidate
              onSubmit={handleSubmit(onSubmit)}
              className="space-y-6"
            >
              <div>
                <div className="flex items-center justify-between">
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    New Password
                  </label>
                </div>
                <div className="mt-2">
                  <input
                    id="password"
                    // {...register('password', {
                    //   required: 'password is required',
                    //   pattern: {
                    //     value:
                    //       /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/gm,
                    //     message: `- at least 8 characters\n
                    //     - must contain at least 1 uppercase letter, 1 lowercase letter, and 1 number\n
                    //     - Can contain special characters`,
                    //   },
                    // })}
                    {...register("password", {
                      required: "password is required",
                    })}
                    type="password"
                    autoComplete="new-password"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  {errors.password && (
                    <p className="text-red-500">{errors.password.message}</p>
                  )}
                </div>
              </div>

              <div>
                <div className="flex items-center justify-between">
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Confirm Password
                  </label>
                </div>
                <div className="mt-2">
                  <input
                    id="confirmPassword"
                    {...register("confirmPassword", {
                      required: "confirm password is required",
                      validate: (value, formValues) =>
                        value === formValues.password ||
                        "password not matching",
                    })}
                    type="password"
                    autoComplete="new-password"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  {errors.confirmPassword && (
                    <p className="text-red-500">
                      {errors.confirmPassword.message}
                    </p>
                  )}
                  {/* {passwordReset && (
                <p className="text-green-500">Password Reset</p>
              )} */}
                  {error && <p className="text-red-500">{error}</p>}
                </div>
              </div>
              <div>
                {btnLoadin ? (
                  <button
                    type="submit"
                    className="flex w-full justify-center rounded-md bg-purple-700 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-purple-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-600"
                  >
                    Loading...
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="flex w-full justify-center rounded-md bg-purple-700 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-purple-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-600"
                  >
                    Reset Password
                  </button>
                )}
              </div>
            </form>

            <p className="mt-10 text-center text-sm text-gray-500">
              Send me back to{" "}
              <Link
                to="/login"
                className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
              >
                Login
              </Link>
            </p>
          </div>
        </div>
    ) : (
         <EmailFailedResetPasswrod />
       )}
    </>
  );
}